import React from 'react';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

const BackgroundSection = ({ className, fluid, children, bgColor = '#101010' }) => (
  <BackgroundImage Tag="div" className={className} fluid={fluid} backgroundColor={bgColor}>
    {children}
  </BackgroundImage>
);

const PostImage = styled(BackgroundSection)`
  margin: 0 auto;
  height: 20rem;
  @media (max-width: ${props => props.theme.mdWidth}) {
    height: 18rem;
  }
  width: 100%;
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  > h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 4rem;
    @media (max-width: ${props => props.theme.mdWidth}) {
      font-size: 3rem;
    }
    @media (max-width: ${props => props.theme.smWidth}) {
      font-size: 2.5rem;
    }
    text-align: center;
    color: #fff;
    width: 100%;
    background-color: rgba(16, 16, 16, 0.5);
    ${props => props.theme.container};
    padding: 2rem 5rem 1rem;
  }
`;

export default PostImage;
