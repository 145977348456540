import styled from 'styled-components';
import { container } from '../../styles/mixins';

const PostContent = styled.div`
  background-color: rgb(16, 16, 16);
  color: #fff;
  width: 100%;
  > div > p {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2rem;
    line-height: 2.1rem;
    font-weight: 300;
  }
  ${props => container(props)};
  @media (max-width: ${props => props.theme.mdWidth}) {
    margin-top: 2rem;
  }
`;

export default PostContent;
