import React from 'react';
import styled from 'styled-components';
import PostImage from './PostImage';
import PostContent from './PostContent';

const Container = styled.div`
  background-color: rgb(16, 16, 16);
  width: 100%;
`;

const PostPage = ({ image, children, title }) => {
  return (
    <Container>
      <PostImage fluid={image}>
        <h1>{title}</h1>
      </PostImage>
      <PostContent>{children}</PostContent>
    </Container>
  );
};

export default PostPage;
