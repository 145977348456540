import React from 'react';
import { graphql, navigate } from 'gatsby';
import { scroller } from 'react-scroll';
import showdown from 'showdown';
import styled from 'styled-components';
import { rem } from 'polished';
import CoreLayout from '../layouts/CoreLayout';
import PostPage from '../components/PostPage';
import NormalPanel from '../components/NormalPanel';

const converter = new showdown.Converter();

const BookButtonContainer = styled.div`
  height: ${rem('200px')};
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
`;

const BookButton = styled.button`
  margin: 0 auto;
  background-color: #ac0000 !important;
  color: #fff !important;
  border-radius: 0 !important;
  padding: ${rem('15px')} ${rem('25px')} !important;
  &:hover {
    background-color: #840000 !important;
  }
`;

const bookAction = () => {
  if (window.location.pathname === '/') {
    scroller.scrollTo('leadFormPanel', {
      smooth: true,
      duration: 500,
    });
  } else {
    navigate('/', {
      state: {
        toLeadPanel: true,
      },
    });
  }
};

const AboutPage = ({ data }) => {
  const { image, bookBackgroundImage, content } = data.aboutPageContent;
  return (
    <CoreLayout>
      <PostPage image={image.childImageSharp.fluid} title="About Us">
        <div
          dangerouslySetInnerHTML={{
            __html: converter.makeHtml(content),
          }}
        />
      </PostPage>
      <NormalPanel
        backgroundImage={bookBackgroundImage.childImageSharp.fluid}
        fullScreen={false}
      >
        <BookButtonContainer>
          <BookButton
            className="btn btn-lg"
            onClick={bookAction}
          >
            Book Now
          </BookButton>
        </BookButtonContainer>
      </NormalPanel>
    </CoreLayout>
  );
};

export const query = graphql`
  query AboutPageQuery {
    aboutPageContent {
      pageTitle
      content
      image {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bookBackgroundImage {
        childImageSharp {
          fluid(quality: 80, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;

export default AboutPage;
